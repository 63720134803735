import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/labs/labs.mbanq.io/src/components/blog/Post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`AWS Visit`}</h1>
    <p>{`We, at `}<a href="https://mbanq.com" target="_blank">{`Mbanq`}</a>{`, love `}<a href="https://aws.amazon.com" target="_blank">{`AWS`}</a>{`. As a matter of fact we run `}<a href="https://console.cloud.mbanq.com" target="_blank">{`Mbanq Console`}</a>{` on AWS and use a lot of
services provided by this great cloud provider to let you rollout your own
Core Banking System together with web-based back office and web banking
applications complety on a click of a button on our `}<a href="https://console.cloud.mbanq.com" target="_blank">{`evaluation environment`}</a>{`.`}</p>
    <p>{`We also run multiple banks in production on `}<a href="https://aws.amazon.com" target="_blank">{`AWS`}</a>{`. So, if you're planning to run your next bank on `}<a href="https://console.cloud.mbanq.com" target="_blank">{`Mbanq Cloud`}</a>{` - you should definitely do so - you can be sure that it will run on `}<a href="https://aws.amazon.com" target="_blank">{`AWS`}</a>{`.`}</p>
    <p>{`As you probably know `}<a href="https://aws.amazon.com" target="_blank">{`AWS`}</a>{` is also very big
with the startup support and we're very happy that the guys at `}<a href="https://aws.amazon.com/events/asean/" target="_blank">{`AWS ASEAN`}</a>{` agreed to support
startups joining Mbaq Labs.`}</p>
    <p>{`We would really like to thank `}<a href="https://www.linkedin.com/in/fannie/" target="_blank">{`Fannie Soubiele`}</a>{` for supporting Mbanq Labs from the very
beginning and helping to on-board our startups to the AWS program.`}</p>
    <p>{`Last week Mbanq Labs startup teams had the chance to visit the AWS office in Singapore and meet `}<a href="https://www.linkedin.com/in/fannie/" target="_blank">{`Fannie`}</a>{` from the Global Startup Business Development Team and learn about the assistance that AWS provides to startups. Some of these programs include AWS Activate, AWS Marketplace and AWS Launchpad.`}</p>
    <p>{`As part of Mbanq Labs acceleration program, all startups receive up to $10,000 in AWS Promotional Credit valid for 2 Years, plus 1 year of AWS Business Support (up to $5,000) and 80 credits for self-paced labs ($80 value).`}</p>
    <p>{`Additionally to that all Mbanq Labs startups are eligible for the Mbanq Cloud
credits. The Mbanq Cloud credits will allow the startups to either offer their
services to existing clients of Mbanq of maybe even to run their own bank on
Mbanq Cloud infrastructure.`}</p>
    <p>{`Join Mbanq Labs to receive the same support!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      